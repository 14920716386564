import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Service } from "../config/service";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link, Button } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import { getTrainKmlUrl, convertTimeFormat } from '../utils/index';
import { disabledInspect } from '../utils/index';

import { withScriptjs, withGoogleMap, InfoWindow, GoogleMap, KmlLayer, Marker } from "react-google-maps";

let mapKey = 0;

let trainObj = {
  TrainId: '',
  TrainName: ''
}

const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap key={props.mapKey} defaultZoom={8} options={{ scrollwheel: true }} defaultCenter={{ lat: 30.3753, lng: 69.3451 }}>

      <KmlLayer
        url={props.kmlUrl}
        options={{ preserveViewport: true }}
      />

      {props.isMarkerShown && (
        props.markerPositions.map((place, i) => (
          <Marker
            key={i}
            icon={props.nextStationIcon}
            position={{ lat: place.Latitude, lng: place.Longitude }}
            onClick={() => props.infoWindow(i, place)}
          >
            {i === props.infoWindowID &&
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <span>
                  <b>{place.StationName}</b>
                  <br />
                  {place.ArrivalTime !== null &&
                    <span>Arrival Time: {convertTimeFormat(place.ArrivalTime)}</span>
                  }
                  <br />
                  {place.DepartureTime !== null &&
                    <span>Departure Time: {convertTimeFormat(place.DepartureTime)}</span>
                  }
                </span>
              </InfoWindow>
            }
          </Marker>
        ))
      )}

    </GoogleMap>
  ))
);


function TrainScheduleMap(props) {

  const { currentPath } = props;

  const { id } = useParams();
  const [trainId, setTrainId] = React.useState(id);
  const [allTrains, setAllTrains] = React.useState([]);
  const [trainStations, setTrainStations] = React.useState([]);
  const [trainName, setTrainName] = React.useState('');
  const [markerPositions, setMarkerPositions] = React.useState([]);
  const [kmlUrl, setKmlUrl] = React.useState('');
  const nextStationIcon = { url: images.nextStation, scaledSize: { width: 25, height: 25 } };

  // *For Info Window
  const [infoWindowID, setInfoWindowID] = React.useState("");

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainDetailByTrainID = async (ID) => {
    try {
      const { Response } = await Service.getTrainDetailByTrainID(ID);
      trainObj = {
        TrainId: Response.TrainId,
        TrainName: Response.TrainName,
      }
      getTrainStationsByTrainID(trainObj)
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainStationsByTrainID = async (obj) => {
    try {
      mapKey++;
      const { Response } = await Service.getTrainStationsByTrainID(obj.TrainId);
      setTrainName(obj.TrainName);
      setKmlUrl(getTrainKmlUrl(obj.TrainName))
      setTrainStations([]);
      setMarkerPositions([]);
      trainStations.push(Response);
      setMarkerPositions(trainStations[0]);
      setTrainId(obj.TrainId);
    } catch (error) {
      console.log('Error -> error', error);
    }
  };

  const infoWindow = (index) => {
    try {
      setInfoWindowID(index)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    disabledInspect();
    getAllTrains();
    getTrainDetailByTrainID(id);
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div id="Train-Schedule">

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} sm={12} xs={12}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">Train Schedule</Typography>
            </Grid>

            <Grid container item md={6} sm={9} xs={12} alignItems="center">

              <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                onChange={(event, value) => getTrainStationsByTrainID(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Train" variant="outlined" />
                )}
              />

            </Grid>

            <Grid container item md={2} sm={3} xs={12} alignItems="center">
              <Link href={"/train-schedule-list/" + trainId}>
                <Button key={trainId} variant="contained">
                  Grid View
                </Button>
              </Link>
            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Map ========== */}

      <div className="map" style={{ position: 'relative', }}>
        <MapComponent
          isMarkerShown
          key={mapKey}
          kmlUrl={kmlUrl}
          infoWindowID={infoWindowID}
          markerPositions={markerPositions}
          nextStationIcon={nextStationIcon}
          infoWindow={(index) => infoWindow(index)}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBkpzP7FX_KDgUFplVIq5gpB_KhakQmbGI&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>

      {/* ========== Selected Train ========== */}

      <div className="selected-train">
        <Typography component="h1">{trainName}</Typography>
      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="">
        <div className="" key={currentPath}>
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879478'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default TrainScheduleMap;