import React, { useEffect } from "react";
import images from '../assets/Image';

import Header from '../layouts/header.js';
import Footer from '../layouts/footer.js';

import { Container, Link, Typography, Button, Grid, useMediaQuery } from '@material-ui/core';
import { Responsive, disabledInspect, getValue } from '../utils/index';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const tabs = [
  { id: 1, name: 'train-update', },
  { id: 2, name: 'train-schedule', },
  { id: 3, name: 'station-update', },
  { id: 4, name: 'station-schedule', },
]

function Index(props) {

  const { currentPath } = props;

  const isMobile = useMediaQuery(Responsive.isMobile);

  useEffect(() => {
    disabledInspect();
    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})
    window.scrollTo({ top: 0 });
  }, [currentPath]);

  return (
    <div id="Index">

      {/* ========== Header ========== */}

      <Header />

      {/* ========== Banner ========== */}

      <div className="banner">
        <div className="overlay"></div>
        <div className="button-wrapper">
          <Button variant="contained">
            <Link href="/live-tracking">
              <img src={images.mapMaker} alt="Tracking" />
              Live Tracking
            </Link>
          </Button>
        </div>
      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className=""> 
        <div className="" key={currentPath}> 
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879471'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Features ========== */}

      <div className="features">
        <Container maxWidth="lg">

          <Grid container spacing={isMobile ? 2 : 4}>
            {
              tabs.map(tab => (
                <Grid key={`tab${tab.id}`} item md={6} sm={6} xs={12}>
                  <Link href={`/${tab.name}`}>
                    <div className={tab.name}>
                      <div className="overlay"></div>
                      <Typography component="h1">{getValue(tab.name)}</Typography>
                    </div>
                  </Link>
                </Grid>
              ))
            }
          </Grid>

        </Container>
      </div>

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default Index;