import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Index from '../pages/index';
import About from '../pages/about-us';
import Contact from '../pages/contact-us';
import TermsConditions from '../pages/terms-conditions';
import LiveTracking from '../pages/live-tracking';
import TrainUpdateList from '../pages/train-update-list';
import TrainSchedule from '../pages/train-schedule';
import TrainScheduleList from '../pages/train-schedule-list';
import TrainScheduleMap from '../pages/train-schedule-map';
import StationUpdateList from '../pages/station-update-list';
import StationScheduleList from '../pages/station-schedule-list';
import AllTrains from '../pages/AllTrains';

const AppRoutes = () => {

  return (

    <BrowserRouter>
      <Route component={Index} path="/" exact />
      <Route component={About} path="/about-us" exact />
      <Route component={Contact} path="/contact-us" exact />
      <Route component={TermsConditions} path="/terms-conditions" exact />
      <Route component={LiveTracking} path="/live-tracking" exact />
      <Route component={AllTrains} path="/all-trains" exact />
      <Route component={TrainUpdateList} path="/train-update" exact />
      <Route component={TrainSchedule} path="/train-schedule" exact />
      <Route component={TrainScheduleList} path="/train-schedule-list/:id" exact />
      <Route component={TrainScheduleMap} path="/train-schedule-map/:id" exact />
      <Route component={StationUpdateList} path="/station-update" exact />
      <Route component={StationScheduleList} path="/station-schedule" exact />
    </BrowserRouter>

  );
}

export default AppRoutes;