import axios from 'axios';

export const server = 'https://api.pakraillive.com/api/';
export const externalAPI = 'http://198.71.60.173:8050/api/';
export const contactServer = 'https://contactus.pakraillive.com:8050/api/';

export const instance = axios.create({
  baseURL: server
});

export const extInstance = axios.create({
  baseURL: externalAPI
});

export const contactInstance = axios.create({
  baseURL: contactServer
});