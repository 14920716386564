import React, { useEffect } from "react";
import { Service } from "../config/service";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link, TableContainer, Table, TableHead, TableBody, TableFooter, TableRow, TableCell, Paper, TablePagination } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import { getTrainKmlUrl, convertTimeFormat } from '../utils/index';
import { disabledInspect } from '../utils/index';
import TrainDialog from "../utils/train-dialog";

import { withScriptjs, withGoogleMap, InfoWindow, GoogleMap, KmlLayer, Marker } from "react-google-maps";

let mapKey = 0;

const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap key={props.mapKey} defaultZoom={8} options={{ scrollwheel: true }} defaultCenter={{ lat: 30.3753, lng: 69.3451 }}>

      <KmlLayer
        url={props.kmlUrl}
        options={{ preserveViewport: true }}
      />

      {props.isMarkerShown && (
        props.markerPositions.map((place, i) => (
          <Marker
            key={i}
            icon={props.nextStationIcon}
            position={{ lat: place.Latitude, lng: place.Longitude }}
            onClick={() => props.infoWindow(i, place)}
          >
            {i === props.infoWindowID &&
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <span>
                  <b>{place.StationName}</b>
                  <br />
                  {place.ArrivalTime !== null &&
                    <span>Arrival Time: {convertTimeFormat(place.ArrivalTime)}</span>
                  }
                  <br />
                  {place.DepartureTime !== null &&
                    <span>Departure Time: {convertTimeFormat(place.DepartureTime)}</span>
                  }
                </span>
              </InfoWindow>
            }
          </Marker>
        ))
      )}

    </GoogleMap>
  ))
);


function StationScheduleList(props) {

  const [allTrains, setAllTrains] = React.useState([]);
  const [trainStations, setTrainStations] = React.useState([]);
  const [trainName, setTrainName] = React.useState('');
  const [markerPositions, setMarkerPositions] = React.useState([]);
  const [kmlUrl, setKmlUrl] = React.useState('');
  // const [isOpen, setIsOpen] = React.useState(false);
  const nextStationIcon = { url: images.nextStation, scaledSize: { width: 25, height: 25 } };

  // *For Train Dialog
  const [trainDialogOpen, setTrainDialogOpen] = React.useState(false);

  // *For Info Window
  const [infoWindowID, setInfoWindowID] = React.useState("");

  //  *For Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serialNumber, setSerialNumber] = React.useState(0);

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  // *For Train Dialog
  const closeTrainDialog = (obj) => {
    try {
      getTrainStationsByTrainID(obj);
      setTrainDialogOpen(false)
    } catch (e) {
      console.log(e);
    }
  };

  const getTrainStationsByTrainID = async (obj) => {
    try {
      mapKey++;
      const { Response } = await Service.getTrainStationsByTrainID(obj.TrainId);
      setTrainName(obj.TrainName);
      setKmlUrl(getTrainKmlUrl(obj.TrainName))
      setTrainStations([]);
      setMarkerPositions([]);
      trainStations.push(Response);
      setMarkerPositions(trainStations[0]);
    } catch (error) {
      console.log('Error -> error', error);
    }
  };

  const changeTablePage = (event, newPage) => {
    let sr = newPage;
    setPage(newPage);
    if (sr === 0) {
      sr = newPage;
    } else {
      sr = newPage * 10;
    }
    setSerialNumber(sr);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const infoWindow = (index) => {
    try {
      setInfoWindowID(index)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    disabledInspect();
    getAllTrains();
    setTrainDialogOpen(true);
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div id="Train-Schedule">

      {/* ========== Train Dialog ========== */}

      <TrainDialog open={trainDialogOpen} onClose={closeTrainDialog} allTrains={allTrains} />

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} xs={12}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">Train Schedule</Typography>
            </Grid>

            <Grid container item md={7} xs={12} alignItems="center">

              <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                getOptionDisabled={option => option.IsLive === true ? false : true}
                onChange={(event, value) => getTrainStationsByTrainID(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Train" variant="outlined" />
                )}
              />

            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Map ========== */}

      <div className="map">
        <MapComponent
          isMarkerShown
          key={mapKey}
          kmlUrl={kmlUrl}
          infoWindowID={infoWindowID}
          markerPositions={markerPositions}
          nextStationIcon={nextStationIcon}
          infoWindow={(index) => infoWindow(index)}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBkpzP7FX_KDgUFplVIq5gpB_KhakQmbGI&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>

      {/* ========== Selected Train ========== */}

      <div className="selected-train">
        <Typography component="h1">{trainName}</Typography>
      </div>

      {/* ========== Train Grid ========== */}

      <div className="train-grid">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={10}>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '40px', }}>Sr. #</TableCell>
                      <TableCell>	Station Name</TableCell>
                      <TableCell>Arrival</TableCell>
                      <TableCell>Departure</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? markerPositions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : markerPositions
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {(serialNumber) + (index + 1)}
                        </TableCell>
                        <TableCell>{row.StationName}</TableCell>
                        <TableCell>{row.ArrivalTime === null ? '--:--' : row.ArrivalTime}</TableCell>
                        <TableCell>{row.DepartureTime === null ? '--:--' : row.DepartureTime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        colSpan={3}
                        count={markerPositions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changeTablePage}
                        onRowsPerPageChange={changeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>
        </Container>

      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="g-ads-wrapper">
        <div className="g-ads" key={currentPath}>
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879478'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default StationScheduleList;