import React, { useEffect, useState } from "react";
import { Service } from "../config/service";
import { socketConfig } from "../config/socket";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import ReactMapboxGl, { Marker, ZoomControl, ScaleControl, Popup, GeoJSONLayer } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapbox from 'mapbox-gl';
import geojsons from "../assets/geojsons";
import { disabledInspect, getTrainLastUpdate } from "../utils/index";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapbox.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


// const Map = ReactMapboxGl({
//     accessToken:
//         'pk.eyJ1IjoiemVlbWFuZ290ZWNoIiwiYSI6ImNrdHR1bnVjZzBic3gydm1yZ3E2d2NkdHMifQ.xpEpDcB_0YGl5zI3cUxXGA'
// });

let PK_LONG = 69.3451;
let PK_LAT = 30.3753;
let ALL_ZOOM = [4.5];

var timer;
let mapKey = 0;
let socket = null;
let trainsIds = [];
let zoom = ALL_ZOOM
let center = [PK_LONG, PK_LAT];

const symbolLayout = {
    'text-field': '{place}',
    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
    'text-offset': [0, 0.6],
    'text-anchor': 'top'
};
const symbolPaint = {
    'text-color': 'white'
};

const lineLayout = { visibility: 'visible' };
const linePaint = {
    'line-color': 'black'
};

const allTrains = [
    { TrainId: 0, TrainName: 'All UP Trains', TrainNumber: 0, IsUp: true, IsLive: true },
    { TrainId: 0, TrainName: 'All DOWN Trains', TrainNumber: 0, IsUp: false, IsLive: true }
]

const AllTrains = React.memo(props => {

    const { currentPath } = props;

    // *For Train Dialog
    const [trainDialogOpen, setTrainDialogOpen] = React.useState(false);

    // *For All Train (UP & DOWN)
    const [isAllTrain, setIsAllTrain] = React.useState(false);
    const [allTrainMarkerPosition, setAllTrainMarkerPosition] = React.useState([]);

    // *For Single Train
    const [isTrainUp, setIsTrainUP] = React.useState('');
    const [geojson, setGeojson] = useState(null);
    const [_allTrains, setAllTrains] = useState([]);


    const [popUpData, setPopUpData] = React.useState({});
    const [showPopUp, setShowPopUp] = useState(false);

    const getAllTrains = async () => {
        try {
            const { Response } = await Service.getAllTrains();
            if (!Response) return false;
            Response.splice(0, 0, { TrainId: 0, TrainName: 'All UP Trains', TrainNumber: 0, IsUp: true, IsLive: true });
            Response.splice(1, 0, { TrainId: 0, TrainName: 'All DOWN Trains', TrainNumber: 0, IsUp: false, IsLive: true });
            setAllTrains(Response);
        } catch (error) {
            console.log('Login -> error', error);
        }
    };

    // *For All Train (UP & DOWN)
    const getAllTrainStatus = (value, position) => {
        try {
            trainsIds = [];
            if (socket?.disconnect) {
                socket.off('train-status', (e) => null);
                socket.off('all-trains', (e) => null);
                clearTimeout(timer);
            }

            setGeojson(geojsons['All-Trains']);

            setIsTrainUP(position === 'UP');

            _allTrains.forEach((value) => {
                if (position === 'UP') {
                    if (value.IsUp === true) {
                        trainsIds.push(value.TrainNumber)
                    }
                } else {
                    if (value.IsUp === false) {
                        trainsIds.push(value.TrainNumber)
                    }
                }
            });

            const trains = {
                trainId: trainsIds
            };

            socket = socketConfig();
            let isSocketConnected;

            socket.on("connect", () => {
                socket.emit("all-trains", trains);
                isSocketConnected = true;
            });

            socket.on("disconnect", () => {
                //console.log('on disconnect =>', socket.disconnect)
                isSocketConnected = false;
            });

            socket.on("connect_error", (err) => {
                setTimeout(() => {
                    socket.connect();
                }, 1000);
            });

            if (isSocketConnected === false) {
                //console.log(socket.disconnected);
                socket.connect();
            }

            socket.on('all-trains', obj => {
                setIsAllTrain(true);
                setAllTrainsMarkerPosition(obj);
                timer = setTimeout(() => {
                    socket.emit("all-trains", trains);
                }, 2000);
            });

        } catch (e) {
            console.log(e);
        }
    };

    const setAllTrainsMarkerPosition = socketObj => {
        let updatedSocketData = [];

        for (const trainId in socketObj) {
            const element = socketObj[trainId];
            for (const trainKey in element) {
                const trainObj = element[trainKey];
                if (trainsIds.some(id => +id === +trainId)) {
                    let obj = {
                        Latitude: +trainObj.lat,
                        Longitude: +trainObj.lon,
                        LastUpdate: +trainObj.last_updated,
                        Speed: +trainObj.sp,
                        TrainName: _allTrains.find(train => +train.TrainNumber === +trainId).TrainName,
                        trainKey, trainId
                    }
                    updatedSocketData.push(obj);
                }
            }
        }
        setAllTrainMarkerPosition(updatedSocketData);
        // *refresh map only first time
        if (mapKey <= 1) {
            mapKey++;
        }
    };

    // *For Train Dialog
    const closeTrainDialog = obj => {
        mapKey = 0;
        clearTimeout(timer);
        getAllTrainStatus(obj, obj.TrainName === 'All UP Trains' ? 'UP' : 'DOWN');
        setTrainDialogOpen(false)
    };

    const infoWindow = (index, markerInfo, clickFrom) => {
        try {
            let updatedPopUpData = { ...popUpData };
            updatedPopUpData = { ...markerInfo };
            updatedPopUpData.type = clickFrom;
            updatedPopUpData.index = index;
            updatedPopUpData.LastUpdate = getTrainLastUpdate(markerInfo.LastUpdate);
            setPopUpData(updatedPopUpData);
            setShowPopUp(true);
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        setTrainDialogOpen(true);
        disabledInspect();
        // window.adsbygoogle = window.adsbygoogle || [];
        // window.adsbygoogle.push({});
        window.scrollTo({ top: 0 });
        getAllTrains();
    }, [currentPath]);

    console.log('render')

    return (
        <div id="live-tracking">

            <Dialog
                open={trainDialogOpen}
                onClose={closeTrainDialog}
                disableEscapeKeyDown
                disableBackdropClick
            >
                <DialogTitle id="train-dialog-title" style={{ width: "auto" }}>{"Select Type"}</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        className="train-dialog-select"
                        options={allTrains}
                        getOptionLabel={(option) => option.TrainName}
                        getOptionDisabled={option => option.IsLive === true ? false : true}
                        onChange={(event, value) => closeTrainDialog(value)}
                        renderInput={(params) => (
                            <TextField {...params} label="Search Type" variant="outlined" />
                        )}
                    />
                </DialogContent>
            </Dialog>

            <div className="filter">
                <Container maxWidth="lg">
                    <Grid container spacing={2} justifyContent="space-between">

                        <Grid item md={4} xs={12}>
                            <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
                            <Typography component="h2">live tracking</Typography>
                        </Grid>

                        <Grid container item md={7} xs={12} alignItems="center">

                            <Autocomplete
                                options={allTrains}
                                getOptionLabel={(option) => option.TrainName}
                                getOptionDisabled={option => option.IsLive === true ? false : true}
                                onChange={(event, value) => closeTrainDialog(value)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Search Train" variant="outlined" />
                                )}
                            />

                        </Grid>

                    </Grid>
                </Container>
            </div>



            {/* ========== Map ========== */}

            {/* <div className="map">
                <Map
                    // eslint-disable-next-line react/style-prop-object
                    style="mapbox://styles/mapbox/streets-v9"
                    onClick={setShowPopUp.bind(this, false)}
                    zoom={zoom}
                    center={center}
                    containerStyle={{
                        height: '100%',
                    }}
                >
                    <ZoomControl />
                    <ScaleControl />

                    <GeoJSONLayer
                        data={geojson}
                        lineLayout={lineLayout}
                        linePaint={linePaint}
                        symbolLayout={symbolLayout}
                        symbolPaint={symbolPaint}
                    />
                    All Train Markers
                    {isAllTrain && (
                        allTrainMarkerPosition.map((place, i) => {
                            return (
                                <Marker
                                    key={i}
                                    coordinates={[place.Longitude, place.Latitude]}
                                    onClick={() => infoWindow(i, place, 'all-trains')}
                                >
                                    <img src={isTrainUp === true ? images.upTrain : images.dnTrain}
                                        alt="marker" style={{ height: 25, width: 25 }}
                                    />
                                </Marker>
                            )
                        })
                    )}
                    {
                        showPopUp &&
                        <Popup
                            coordinates={[popUpData?.Longitude, popUpData?.Latitude]}
                            offset={{
                                'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
                            }}
                            onClick={setShowPopUp.bind(this, false)}
                        >
                            {
                                popUpData.type === 'all-trains' &&
                                <span>
                                    <b>{popUpData.TrainName}</b>
                                    <br />
                                    Speed: {popUpData.Speed} km/hr
                                    <br />
                                    Last Update: {popUpData.LastUpdate}
                                </span>
                            }
                        </Popup>
                    }
                </Map>
            </div> */}

            {/* ========== Google Ads ========== */}

            {/* <div className="">
                <div className="" key={currentPath}> 
                    <ins className='adsbygoogle'
                        style={{ display: 'block' }}
                        data-ad-client='ca-pub-2174876305920391'
                        data-ad-slot='4162879472'
                        data-ad-format='auto'
                        data-full-width-responsive="true">
                    </ins>
                </div>
            </div> */}

            {/* ========== Footer ========== */}

            <Footer />

        </div>
    )
})

export default AllTrains;


