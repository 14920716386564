import { Apis, contact_post, ExtApis, get } from './';
import qs from 'query-string';

export const Service = {
    getAllTrains: async () => {
        let result = await get(`${Apis.getAllTrains}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTrainDetailByTrainID: async (ID) => {
        let result = await get(`${Apis.getTrainDetailByTrainID}?Id=${ID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getTrainStationsByTrainID: async (ID) => {
        let result = await get(`${Apis.getTrainStationsByTrainID}?Id=${ID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    // *For Contact Form
    postContactUsForm: async data => {
        let result = await contact_post(`${ExtApis.postContactUsForm}`, qs.stringify(data));
        if (result.status === 200) return result.data;
        else throw result;
    }
}