import React, { useEffect } from "react";
import { Service } from "../config/service";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, CardMedia, Link } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import { disabledInspect } from '../utils/index';

function TrainUpdateList() {

  const [allTrains, setAllTrains] = React.useState([]);
  const [trainName, setTrainName] = React.useState('');

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainStationsByTrainID = async (obj) => {
    try {
      await Service.getTrainStationsByTrainID(obj.TrainId);
      setTrainName(obj.TrainName);
    } catch (error) {
      console.log('Error -> error', error);
    }
  };

  useEffect(() => {
    disabledInspect();
    getAllTrains();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div id="Train-Update">

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} xs={8}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">Train Update</Typography>
            </Grid>

            <Grid container item md={7} xs={12} alignItems="center">

              <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                getOptionDisabled={option => option.IsLive === true ? false : true}
                onChange={(event, value) => getTrainStationsByTrainID(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Trains" variant="outlined" />
                )}
              />

            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Map ========== */}

      <div className="map">
        {/* <MyMapComponent isMarkerShown /> */}
        <iframe title="frame"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14474.102037061684!2d67.082216!3d24.91416165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33f343ffc8925%3A0xb45cd60183e51d7b!2sChildren&#39;s%20Hospital%20Karachi!5e0!3m2!1sen!2s!4v1629883288589!5m2!1sen!2s" width="100%" height="100%" loading="lazy"></iframe>
      </div>

      {/* ========== Selected Train ========== */}

      <div className="selected-train">
        <Typography component="h1">{trainName}</Typography>
      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="g-ads-wrapper">
        <div className="g-ads">
          <CardMedia
            component="img"
            height="100"
            image={images.googleAds}
          />
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default TrainUpdateList;