import React from "react";

import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

export default function TrainDialog(props) {

  const { open, onClose, allTrains, validateUpDown } = props
  //console.log('file: train-dialog.js => line 10 => TrainDialog => allTrains', allTrains);

  const handleClose = (value) => {
    let obj = {
      TrainId: value.TrainId,
      TrainName: value.TrainName,
      TrainNumber: value.TrainNumber,
      IsUp: value.IsUp
    };
    onClose(obj);
    return obj;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="train-dialog-title" style={{ width: "auto" }}>{"Select Train"}</DialogTitle>
        <DialogContent>
          <Autocomplete
            className="train-dialog-select"
            options={allTrains}
            getOptionLabel={(option) => option.TrainName}
            getOptionDisabled={option => validateUpDown === true ? option.IsLive === true ? false : true : false}
            onChange={(event, value) => handleClose(value)}
            renderInput={(params) => (
              <TextField {...params} label="Search Train" variant="outlined" />
            )}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}