import React, { useEffect } from "react";
import { Service } from "../config/service";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link, TableContainer, Table, TableHead, TableBody, TableFooter, TableRow, TableCell, Paper, TablePagination, Button } from '@material-ui/core';

import { disabledInspect } from '../utils/index';

function TrainSchedule(props) {

  const { currentPath } = props;

  const [allTrains, setAllTrains] = React.useState([]);

  //  *For Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serialNumber, setSerialNumber] = React.useState(0);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allTrains.length - page * rowsPerPage);

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const changeTablePage = (event, newPage) => {
    let sr = newPage;
    setPage(newPage);
    if (sr === 0) {
      sr = newPage;
    } else {
      sr = newPage * 10;
    }
    setSerialNumber(sr);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    disabledInspect();
    getAllTrains();
    window.scrollTo({ top: 0 });
  }, [currentPath]);

  return (
    <div id="Train-Schedule">

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} xs={12}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">Train Schedule</Typography>
            </Grid>

            <Grid container item md={7} xs={12} alignItems="center">

              {/* <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                getOptionDisabled={option => option.IsLive === true ? false : true}
                onChange={(event, value) => getTrainStationsByTrainID(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Train" variant="outlined" />
                )}
              /> */}
              <TextField
                label="Search Train"
                variant="outlined"
              />

            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Train Grid ========== */}

      <div className="train-grid">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={10} xs={12}>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '40px', }}><b>Sr. #</b></TableCell>
                      <TableCell><b>Train Name</b></TableCell>
                      <TableCell align="center"><b>Action</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? allTrains.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : allTrains
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {(serialNumber) + (index + 1)}
                        </TableCell>
                        <TableCell>{row.TrainName}</TableCell>
                        <TableCell align="center">
                          <Link href={"/train-schedule-map/" + row.TrainId}>
                            <Button variant="contained">
                              Map View
                            </Button>
                          </Link>
                          <Link href={"/train-schedule-list/" + row.TrainId}>
                            <Button variant="contained">
                              Grid View
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        colSpan={3}
                        count={allTrains.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changeTablePage}
                        onRowsPerPageChange={changeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>
        </Container>

      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="">
        <div className="" key={currentPath}>
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879478'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default TrainSchedule;