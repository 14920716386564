import React, { useEffect, useState } from "react";
import { Service } from "../config/service";
import { socketConfig } from "../config/socket";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { Train, Speed } from '@material-ui/icons';

import {
  getDateMonth, getLastUpdate, getTrainLastUpdate, timeConvert,
  convertTimeFormat, getTrainStation, getEstArrivalTime, getInfoWindowEstArrivalTime, disabledInspect
} from '../utils/index';
import AlertDialog from '../utils/dialog';
import TrainDialog from '../utils/train-dialog';

import ReactMapboxGl, { Marker, ZoomControl, ScaleControl, Popup, GeoJSONLayer } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapbox from 'mapbox-gl';
import geojsons from "../assets/geojsons";

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapbox.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


// const Map = ReactMapboxGl({
//   accessToken:
//     'pk.eyJ1IjoiemVlbWFuZ290ZWNoIiwiYSI6ImNrdHR1bnVjZzBic3gydm1yZ3E2d2NkdHMifQ.xpEpDcB_0YGl5zI3cUxXGA'
// });

let PK_LONG = 69.3451;
let PK_LAT = 30.3753;
let SINGLE_ZOOM = [8];
let ALL_ZOOM = [4.5];

var timer;
let mapKey = 0;
let socket = null;
let trainsIds = [];
let selectedRideID = null;
let zoom = ALL_ZOOM
let center = [PK_LONG, PK_LAT];
let updated = false;

const symbolLayout = {
  'text-field': '{place}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0.6],
  'text-anchor': 'top'
};
const symbolPaint = {
  'text-color': 'white'
};

const lineLayout = { visibility: 'visible' };
const linePaint = {
  'line-color': 'black'
};


function LiveTracking(props) {

  const { currentPath } = props;
  const [departureDate, setDepartureDate] = React.useState('');
  const [lastUpdate, setLastUpdate] = React.useState('');
  const [nextStation, setNextStation] = React.useState('');
  const [estimatedArrival, setEstimatedArrival] = React.useState('');
  const [delay, setDelay] = React.useState('');
  const [currentSpeed, setCurrentSpeed] = React.useState('');

  // *For Train Dialog
  const [trainDialogOpen, setTrainDialogOpen] = React.useState(false);

  // *For Alert Dialog
  const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);

  const [trainObjKeys, setTrainObjKeys] = React.useState([]);
  const [trainObj, setTrainObj] = React.useState('');

  // *For All Train (UP & DOWN)
  const [allTrains, setAllTrains] = React.useState([]);
  const [isAllTrain, setIsAllTrain] = React.useState(false);
  const [allTrainMarkerPosition, setAllTrainMarkerPosition] = React.useState([]);

  // *For Single Train
  const [trainStations, setTrainStations] = React.useState([]);
  const [trainName, setTrainName] = React.useState('');
  const [markerPositions, setMarkerPositions] = React.useState([]);
  const [trainStationOrderNo, setTrainStationOrderNo] = React.useState(0);
  const [currentLatitude, setCurrentLatitude] = React.useState(null);
  const [currentLongitude, setCurrentLongitude] = React.useState(null);
  const [isTrainUp, setIsTrainUP] = React.useState('');
  const [geojson, setGeojson] = useState(null);

  // *For Single Train Info Window
  const [infoWindowDelay, setInfoWindowDelay] = React.useState('');

  const [popUpData, setPopUpData] = React.useState({});
  const [showPopUp, setShowPopUp] = useState(false);

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainStationsByTrainID = async (ID) => {
    try {
      const { Response } = await Service.getTrainStationsByTrainID(ID);
      setTrainStations([]);
      setMarkerPositions([]);
      trainStations.push(Response);
      setMarkerPositions(trainStations[0]);
      mapKey++; // *refresh map only first time
    } catch (error) {
      console.log('Error -> error', error);
    }
  };

  // *For Single Train
  const getTrainStatus = (value) => {
    try {
      if (socket?.disconnect) {
        socket.off('train-status', (e) => null);
        socket.off('all-trains', (e) => null);
        selectedRideID = null;
        clearTimeout(timer);
      }

      let trainNumber;
      if (value !== null) {
        trainNumber = value.TrainNumber
        const updatedTrainName = value.TrainName.replace(/ /g, "-");
        if (geojsons[updatedTrainName]) setGeojson(geojsons[updatedTrainName]);
        else setGeojson(geojsons['All-Trains']);
        setIsTrainUP(value.IsUp);
        setTrainName(value.TrainName);
        getTrainStationsByTrainID(value.TrainId);
      } else {
        return;
      }

      const trains = {
        trainId: trainNumber
      };

      socket = socketConfig();
      let isSocketConnected;

      socket.on("connect", () => {
        socket.emit("train-status", trains);
        isSocketConnected = true;
      });

      socket.on("disconnect", () => {
        //console.log('on disconnect =>', socket.disconnect)
        isSocketConnected = false;
      });

      socket.on("connect_error", (err) => {
        setTimeout(() => {
          socket.connect();
        }, 1000);
      });

      if (isSocketConnected === false) {
        //console.log(socket.disconnected);
        socket.connect();
      }

      socket.on('train-status', obj => {
        let arr = [];
        setIsAllTrain(false)
        setAllTrainMarkerPosition(arr);
        if (obj === undefined || obj === null) {
          return;
        } else {
          let rideID;
          let objDetail = [];
          let objKeys = [];
          let objLength = Object.keys(obj).length;
          objDetail.push(obj);
          setTrainObj(obj);

          if (objLength > 1) {
            if (selectedRideID === null) {
              setAlertDialogOpen(true)
              for (let i = 0; i < objLength; i++) {
                rideID = objDetail.flatMap(x => Object.keys(x))[i]
                objKeys.push({ rideID: rideID, departureDate: getDateMonth(rideID), trainID: value.TrainId });
              }
              setTrainObjKeys(objKeys);
            } else {
              updateTrainStatus(obj, selectedRideID, value.TrainId);
            }
          } else {
            rideID = objDetail.flatMap(x => Object.keys(x))[0];
            updateTrainStatus(obj, rideID, value.TrainId);
          }
          timer = setTimeout(() => {
            socket.emit("train-status", trains);
          }, 10000);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  // *For All Train (UP & DOWN)
  const getAllTrainStatus = (value, position) => {
    try {
      trainsIds = [];
      if (socket?.disconnect) {
        socket.off('train-status', (e) => null);
        socket.off('all-trains', (e) => null);
        selectedRideID = null;
        clearTimeout(timer);
      }

      setGeojson(geojsons['All-Trains']);
      setCurrentLatitude(PK_LAT);
      setCurrentLongitude(PK_LONG);

      if (!updated) {
        center = [parseFloat(PK_LONG), parseFloat(PK_LAT)];
        zoom = ALL_ZOOM;
        updated = true;
      }

      if (position === 'UP') {
        setIsTrainUP(true);
      } else {
        setIsTrainUP(false);
      }

      allTrains.forEach((value) => {
        if (position === 'UP') {
          if (value.IsUp === true) {
            trainsIds.push(value.TrainNumber)
          }
        } else {
          if (value.IsUp === false) {
            trainsIds.push(value.TrainNumber)
          }
        }
      });

      const trains = {
        trainId: trainsIds
      };

      socket = socketConfig();
      let isSocketConnected;

      socket.on("connect", () => {
        socket.emit("all-trains", trains);
        isSocketConnected = true;
      });

      socket.on("disconnect", () => {
        //console.log('on disconnect =>', socket.disconnect)
        isSocketConnected = false;
      });

      socket.on("connect_error", (err) => {
        setTimeout(() => {
          socket.connect();
        }, 1000);
      });

      if (isSocketConnected === false) {
        //console.log(socket.disconnected);
        socket.connect();
      }

      socket.on('all-trains', obj => {
        let arr = [];
        setIsAllTrain(true);
        setMarkerPositions(arr);
        setAllTrainsMarkerPosition(obj);
        timer = setTimeout(() => {
          socket.emit("all-trains", trains);
        }, 2000);
      });

    } catch (e) {
      console.log(e);
    }
  };

  const setAllTrainsMarkerPosition = (obj) => {
    var rideID;
    let objDetail = [];
    let innerObjDetail = [];
    let objLength = Object.keys(obj).length;
    let innerObjLength;
    objDetail.push(obj);
    let arr = [];
    let trainName;
    for (let i = 0; i < objLength; i++) {
      rideID = objDetail.flatMap(x => Object.keys(x))[i];
      innerObjLength = Object.keys(obj[rideID]).length;
      innerObjDetail.push(obj[rideID]);

      if (innerObjLength > 0) {
        // *Fetch Train Name from All Trains Array
        for (let j = 0; j < allTrains.length; j++) {
          if (trainsIds.includes(+rideID)) {
            if (+rideID === allTrains[j]['TrainNumber']) {
              trainName = allTrains[j]['TrainName'];
              // *Get Key Wise Value From Train Objects
              for (let k = 0; k < innerObjLength; k++) {
                let key = innerObjDetail.flatMap(x => Object.keys(x))[k];
                let lat = +obj[rideID][key]['lat'];
                let lng = +obj[rideID][key]['lon'];
                let lastUpdate = +obj[rideID][key]['last_updated'];
                let speed = +obj[rideID][key]['sp'];
                arr.push({ Latitude: lat, Longitude: lng, LastUpdate: lastUpdate, Speed: speed, TrainName: trainName });
              }
            }
          }
        }
        innerObjDetail = [];
      }

    }
    setAllTrainMarkerPosition(arr);
    // *refresh map only first time
    if (mapKey <= 1) {
      mapKey++;
    }
  };

  const updateTrainStatus = (trainObj, ride_id, train_id) => {
    try {
      let latitude = trainObj[ride_id]['lat'];
      let longitude = trainObj[ride_id]['lon'];
      let lastUpdate = trainObj[ride_id]['last_updated'];
      let lateBy = trainObj[ride_id]['late_by'];
      let speed = trainObj[ride_id]['sp'];
      let nextStationID = +trainObj[ride_id]['next_st'];
      selectedRideID = ride_id;

      setDepartureDate(getDateMonth(ride_id));
      setCurrentSpeed(speed + ' km/hr');
      setLastUpdate(getLastUpdate(lastUpdate));
      setDelay(timeConvert(lateBy));
      setInfoWindowDelay(lateBy);
      setCurrentLatitude(parseFloat(latitude));
      setCurrentLongitude(parseFloat(longitude));

      if (!updated) {
        center = [parseFloat(longitude), parseFloat(latitude)];
        zoom = SINGLE_ZOOM;
        updated = true;
      }

      if (trainStations.length > 0) {
        trainStations[0].forEach(e => {
          if (e.StationDetailsId === nextStationID) {
            setTrainStationOrderNo(e.OrderNumber)
            return;
          }
        });
        setNextStation(getTrainStation(trainStations[0], nextStationID));
        setEstimatedArrival(getEstArrivalTime(trainStations[0], nextStationID, lateBy));
      } else {
        (async () => {
          await getTrainStationsByTrainID(train_id);
          trainStations[0].forEach(e => {
            if (e.StationDetailsId === nextStationID) {
              setTrainStationOrderNo(e.OrderNumber)
              return;
            }
          });
          setNextStation(getTrainStation(trainStations[0], nextStationID));
          setEstimatedArrival(getEstArrivalTime(trainStations[0], nextStationID, lateBy));
        })()
      }
    } catch (e) {
      console.log(e);
    }
  }

  // *For Train Dialog
  const closeTrainDialog = (obj) => {
    updated = false;
    try {
      mapKey = 0;
      clearTimeout(timer);
      if (obj.TrainId === 0) {
        if (obj.TrainName === 'All UP Trains') {
          setTrainName('All UP Trains')
          getAllTrainStatus(obj, 'UP');
        } else {
          setTrainName('All DOWN Trains')
          getAllTrainStatus(obj, 'DOWN');
        }
      } else {
        getTrainStatus(obj);
      }
      setTrainDialogOpen(false)
    } catch (e) {
      console.log(e);
    }
  };

  // *For Alert Dialog
  const closeAlertDialog = (obj) => {
    try {
      updateTrainStatus(trainObj, obj.rideID, obj.trainID);
      setAlertDialogOpen(false)
    } catch (e) {
      console.log(e);
    }
  }

  const infoWindow = (index, markerInfo, clickFrom) => {
    try {
      let updatedPopUpData = { ...popUpData };
      if (clickFrom === 'single-train') {
        updatedPopUpData = { ...markerInfo };
        updatedPopUpData.type = clickFrom;
        updatedPopUpData.index = index;
        updatedPopUpData.ArrivalTime = convertTimeFormat(markerInfo.ArrivalTime);
        updatedPopUpData.estArrivalTime = getInfoWindowEstArrivalTime(markerInfo.ArrivalTime, infoWindowDelay);
      } else {
        updatedPopUpData = { ...markerInfo };
        updatedPopUpData.type = clickFrom;
        updatedPopUpData.index = index;
        updatedPopUpData.LastUpdate = getTrainLastUpdate(markerInfo.LastUpdate);
      }
      setPopUpData(updatedPopUpData);
      setShowPopUp(true);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    disabledInspect();
    setTrainDialogOpen(true);
    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})
    window.scrollTo({ top: 0 });

    getAllTrains();
  }, [currentPath]);

  return (
    <div id="live-tracking">

      {/* ========== Train Dialog ========== */}

      <TrainDialog open={trainDialogOpen} onClose={closeTrainDialog} allTrains={allTrains} validateUpDown={true} />

      {/* ========== Alert Dialog ========== */}

      <AlertDialog open={alertDialogOpen} onClose={closeAlertDialog} trains={trainObjKeys} />

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} xs={12}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">live tracking</Typography>
            </Grid>

            <Grid container item md={7} xs={12} alignItems="center">

              <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                getOptionDisabled={option => option.IsLive === true ? false : true}
                onChange={(event, value) => closeTrainDialog(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Train" variant="outlined" />
                )}
              />

            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Map ========== */}

      {/* <div className="map">
        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/streets-v9"
          onClick={setShowPopUp.bind(this, false)}
          zoom={zoom}
          center={center}
          containerStyle={{
            height: '100%',
          }}
        >
          <ZoomControl />
          <ScaleControl />

          <GeoJSONLayer
            data={geojson}
            lineLayout={lineLayout}
            linePaint={linePaint}
            symbolLayout={symbolLayout}
            symbolPaint={symbolPaint}
          />

          {
            markerPositions.map((place, i) => {
              return (
                <Marker
                  key={`marker${i}`}
                  coordinates={[place.Longitude, place.Latitude]}
                  // position={{ lat: place.Latitude, lng: place.Longitude }}
                  onClick={() => infoWindow(i, place, 'single-train')}
                >
                  <img src={
                    place.OrderNumber < trainStationOrderNo ? images.prevStation
                      : i === markerPositions.length - 1 ? images.lastStation
                        : images.nextStation
                  }
                    alt="marker"
                    style={{ height: 25, width: 25 }}
                  />
                </Marker>
              )
            })
          }
          {isAllTrain === false && (
            <Marker coordinates={[currentLongitude, currentLatitude]}
            >
              <img src={isTrainUp === true ? images.upTrain : images.dnTrain}
                alt="marker" style={{ height: 25, width: 40 }}
              />
            </Marker>

          )}
          All Train Markers
          {isAllTrain && (
            allTrainMarkerPosition.map((place, i) => {
              return (
                <Marker
                  key={i}
                  coordinates={[place.Longitude, place.Latitude]}
                  onClick={() => infoWindow(i, place, 'all-trains')}
                >
                  <img src={isTrainUp === true ? images.upTrain : images.dnTrain}
                    alt="marker" style={{ height: 25, width: 25 }}
                  />
                </Marker>
              )
            })
          )}
          {
            showPopUp &&
            <Popup
              coordinates={[popUpData?.Longitude, popUpData?.Latitude]}
              offset={{
                'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
              }}
              onClick={setShowPopUp.bind(this, false)}
            >
              info window for next stations
              {
                (popUpData.OrderNumber >= trainStationOrderNo && popUpData.type === 'single-train') &&
                <span>
                  <b>{popUpData.StationName}</b>
                  <br />
                  Estimated Arrival: {popUpData?.estArrivalTime}
                  <br />
                  Scheduled Arrival: {popUpData?.ArrivalTime}
                  <br />
                  Delay: {delay}
                </span>
              }

              {
                popUpData.type === 'all-trains' &&
                <span>
                  <b>{popUpData.TrainName}</b>
                  <br />
                  Speed: {popUpData.Speed} km/hr
                  <br />
                  Last Update: {popUpData.LastUpdate}
                </span>
              }



              info window for prev stations
              {popUpData.OrderNumber < trainStationOrderNo &&
                <span>
                  <b>{popUpData.StationName}</b>
                </span>
              }
            </Popup>
          }
        </Map>
      </div> */}

      {/* ========== Tracking Detail ========== */}


      <div className="tracking-detail">
        <div className="card">
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Typography component="h1">{trainName}</Typography>
            </Grid>
          </Grid>
          {isAllTrain === false &&
            <Grid container spacing={2} justifyContent="space-between">

              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <img src={images.lastUpdate} alt="last update" />
                    <Typography className="title" component="span">last update</Typography>
                    <Typography className="detail blink" component="span">{lastUpdate}</Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <img src={images.depDate} alt="departure date" />
                    <Typography className="title" component="span">departure date</Typography>
                    <Typography className="detail" component="span">{departureDate}</Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <Train />
                    <Typography className="title" component="span">next station</Typography>
                    <Typography className="detail" component="span">{nextStation}</Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <img src={images.estArrival} alt="estimated arrival" />
                    <Typography className="title" component="span">estimated arrival</Typography>
                    <Typography className="detail" component="span">{estimatedArrival}</Typography>
                  </Typography>
                </Typography>
              </Grid>

              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <img src={images.trainDelay} alt="train delay" />
                    <Typography className="title" component="span">delay</Typography>
                    <Typography className="detail" component="span">{delay}</Typography>
                  </Typography>
                </Typography>
              </Grid>
              <Grid item md={4} sm={5} xs={12}>
                <Typography component="ul">
                  <Typography component="li">
                    <Speed />
                    <Typography className="title" component="span">current speed</Typography>
                    <Typography className="detail" component="span">{currentSpeed}</Typography>
                  </Typography>
                </Typography>
              </Grid>

            </Grid>
          }
        </div>
      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="">
        <div className="" key={currentPath}> 
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879472'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div >
  )
}

export default LiveTracking


