import React from 'react'
import images from '../assets/Image';

import { Container, Typography, IconButton, Link, Hidden, Drawer } from '@material-ui/core';
import { Menu } from '@material-ui/icons';


function Header() {

  const [mobileMenu, setMobileMenu] = React.useState(false);

  const menuToggle = () => {
    setMobileMenu(!mobileMenu);
  };

  const menu = (
    <Typography component="ul" className="menu">

      <Typography component="li" ><Link href="/">Home</Link></Typography>
      <Typography component="li" ><Link href="/live-tracking">Live Tracking</Link></Typography>
      {/* <Typography component="li" ><Link href="/all-trains">All Trains</Link></Typography> */}
      <Typography component="li" ><Link href="/about-us">About Us</Link></Typography>
      <Typography component="li" ><Link href="/contact-us">Contact Us</Link></Typography>

    </Typography>
  );

  return (
    <div id="Header">

      {/* ========== Top Header ========== */}

      {/* <div className="top-header">
        <Container maxWidth="lg">

          <Grid container spacing={0}>

            <Grid item md={12} xs={12}>
              <Typography component="ul">
                <Typography component="li">
                  <Language />
                  <Link href="#">en</Link> |
                  <Link href="#"> ur</Link>
                </Typography>
              </Typography>
            </Grid>

          </Grid>

        </Container>
      </div> */}

      {/* ========== Main Header ========== */}

      <header>
        <Container maxWidth="lg">

          <nav>
            <div className="logo">
              <Link href="/">
                <img src={images.logo} alt="PakRailLive" />
              </Link>
            </div>

            <Hidden xsDown>
              {menu}
            </Hidden>

            <Hidden only={['lg', 'sm']}>
              <IconButton
                color="inherit"
                edge="end"
                onClick={menuToggle}
              >
                <Menu />
              </IconButton>

              <Drawer
                variant="persistent"
                open={mobileMenu}
                onClose={menuToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {menu}
              </Drawer>

            </Hidden>

          </nav>

        </Container>
      </header>

    </div>
  );
}
export default Header;