import { instance, extInstance, contactInstance } from '../axios';

import { errorHandler } from './errorHandler';

export const Apis = {
    getAllTrains: 'Train/GetLiveTrains',
    getTrainDetailByTrainID: 'Train/GetTrainById',
    getTrainStationsByTrainID: 'Train/GetTrainStationsByTrainId'
};
// *For Contact Form
export const ExtApis = {
    postContactUsForm: 'FeedBacks/SaveFeedBack'
};

export const headers = {
    'content-type': 'application/json',
};

export const get = async (endPoint, token) => {
    try {
        const result = await instance.get(endPoint);
        return result;
    } catch (e) {
        console.log('post -> e', e);
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await instance.post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const put = async (endPoint, data, token) => {
    try {
        const result = await instance.put(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

// *For Contact Form
export const ext_post = async (endPoint, data, token) => {
    try {
        //let headers = { , Authorization: {`Bearer ${token}`}}
        const result = await extInstance.post(endPoint, data, { headers: { "Access-Control-Allow-Origin": "*" } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const contact_post = async (endPoint, data, token) => {
    try {
        //let headers = { , Authorization: {`Bearer ${token}`}}
        const result = await contactInstance.post(endPoint, data, { headers: { "Access-Control-Allow-Origin": "*" } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};