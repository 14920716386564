import React, { useEffect } from "react";
import { Service } from "../config/service";

import Header from '../layouts/header.js';
import Footer from '../layouts/footer.js';

import { Container, Grid, Divider, Typography, Button, TextField, useMediaQuery } from '@material-ui/core';
import { Responsive, disabledInspect } from '../utils/index';

import Swal from 'sweetalert2';


function ContactUs(props) {

  const isMobile = useMediaQuery(Responsive.isMobile);

  const [value, setValue] = React.useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  const resetForm = () => {
    setValue({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
  }

  const { currentPath } = props;

  const submitContactUsForm = async () => {
    try {
      if (value.name === '' || value.phone === '' || value.email === '') {
        Swal.fire('Warning', 'Please Fill Required Fields!', 'warning');
        return;
      } else {
        if (value.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
          const dateTime = new Date().toLocaleTimeString();
          const splitTime = dateTime.split(':');
          const time = splitTime[0] + ":" + splitTime[1];

          const feedBack = {};
          feedBack['Feedback[' + 0 + '].Name'] = value.name;
          feedBack['Feedback[' + 0 + '].PhoneNumber'] = value.phone;
          feedBack['Feedback[' + 0 + '].DeviceID'] = value.email;
          feedBack['Feedback[' + 0 + '].FeedBack'] = value.message;
          feedBack['Feedback[' + 0 + '].FeedBackDate'] = new Date().toLocaleDateString();
          feedBack['Feedback[' + 0 + '].FeedBackTime'] = time;
          const obj = {
            ...feedBack,
            key: 'CU?(HgW[GpeQNq7[WEEV(_wD<,K*4B2k<uC+modelC3s^w(U<',
          };
          await Service.postContactUsForm(obj)
          Swal.fire('Success', 'Thank you for your feedback.', 'success');
          resetForm();
        } else {
          Swal.fire('Warning', 'Email address is not valid', 'warning');
          return;
        }
      }
    } catch (error) {
      Swal.fire('Success', 'Thank you for your feedback.', 'success');
      resetForm();
      console.log('Error -> error', error);
    }
  };

  useEffect(() => {
    disabledInspect();
    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})
    window.scrollTo({ top: 0 });
  }, [currentPath]);

  return (
    <div id="Contact-Us">

      {/* ========== Header ========== */}

      <Header />

      {/* ========== Banner ========== */}

      <div className="banner">
        <div className="overlay"></div>
        <h1>Contact Us</h1>
      </div>

      {/* Ad Container */}
      {/* <div className="">
        <div className="" key={currentPath}>  
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879474'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Contact Form Section ========== */}

      <div className="form">
        <Container maxWidth="xl">

          <Grid container spacing={isMobile ? 1 : 2} justifyContent="space-evenly">

            {/* ========== Form ========== */}

            <Grid item md={8} xs={12}>

              <Typography component="h2">Contact Form</Typography>
              <Divider />
              <form>
                <TextField
                  label="Name"
                  placeholder="Your Name"
                  type="text"
                  value={value.name}
                  onChange={handleChange('name')}
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />


                <TextField
                  label="Phone"
                  placeholder="Phone"
                  type="number"
                  value={value.phone}
                  onChange={handleChange('phone')}
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Email"
                  placeholder="Email"
                  type="email"
                  value={value.email}
                  onChange={handleChange('email')}
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Write Message"
                  placeholder="Message"
                  type="text"
                  value={value.message}
                  onChange={handleChange('message')}
                  fullWidth
                  multiline
                  rows={6}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Button variant="contained" type="button" onClick={() => { submitContactUsForm() }}>
                  Send Message
                </Button>
              </form>

            </Grid>

            {/* ========== Google Ads ========== */}

            {/* <Grid item md={2} xs={12}>
              <div className="" key={currentPath}> 
                <ins className='adsbygoogle'
                  style={{ display: 'block' }}
                  data-ad-client='ca-pub-2174876305920391'
                  data-ad-slot='4162879474'
                  data-ad-format='auto'
                  data-full-width-responsive="true">
                </ins>
              </div>
            </Grid> */}

          </Grid>

        </Container>
      </div>

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default ContactUs;