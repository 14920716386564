import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function AlertDialog(props) {
  const { open, onClose, trains } = props
  const handleListItemClick = (rideID, trainID) => {
    let obj = {
      rideID: rideID,
      trainID: trainID,
    };
    onClose(obj);
    return obj;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="alert-dialog-title">{"Select the train start date:"}</DialogTitle>
        <DialogContent>
          <List>
            {trains.map((item, index) => (
              <ListItem button onClick={() => handleListItemClick(item.rideID, item.trainID)} key={index}>
                <ListItemText primary={item.departureDate} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}