import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Service } from "../config/service";
import images from '../assets/Image';

import Footer from '../layouts/footer.js';

import { Container, TextField, Grid, Typography, Link, TableContainer, Table, TableHead, TableBody, TableFooter, TableRow, TableCell, Paper, TablePagination, Button } from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";

import { disabledInspect } from '../utils/index';

let trainObj = {
  TrainId: '',
  TrainName: ''
}

function TrainScheduleList(props) {

  const { currentPath } = props;

  const { id } = useParams();
  const [trainId, setTrainId] = React.useState(id);
  const [allTrains, setAllTrains] = React.useState([]);
  const [trainStations, setTrainStations] = React.useState([]);
  const [trainName, setTrainName] = React.useState('');
  const [markerPositions, setMarkerPositions] = React.useState([]);

  //  *For Table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [serialNumber, setSerialNumber] = React.useState(0);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, allTrains.length - page * rowsPerPage);

  const getAllTrains = async () => {
    try {
      const { Response } = await Service.getAllTrains();
      setAllTrains(Response);
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainDetailByTrainID = async (ID) => {
    try {
      const { Response } = await Service.getTrainDetailByTrainID(ID);
      trainObj = {
        TrainId: Response.TrainId,
        TrainName: Response.TrainName,
      }
      getTrainStationsByTrainID(trainObj)
    } catch (error) {
      console.log('Login -> error', error);
    }
  };

  const getTrainStationsByTrainID = async (obj) => {
    try {
      const { Response } = await Service.getTrainStationsByTrainID(obj.TrainId);
      setTrainName(obj.TrainName);
      setTrainStations([]);
      setMarkerPositions([]);
      trainStations.push(Response);
      setMarkerPositions(trainStations[0]);
      setTrainId(obj.TrainId)
    } catch (error) {
      console.log('Error -> error', error);
    }
  };

  const changeTablePage = (event, newPage) => {
    let sr = newPage;
    setPage(newPage);
    if (sr === 0) {
      sr = newPage;
    } else {
      sr = newPage * 10;
    }
    setSerialNumber(sr);
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    disabledInspect();
    getAllTrains();
    getTrainDetailByTrainID(id);
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div id="Train-Schedule">

      {/* ========== Filter ========== */}

      <div className="filter">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="space-between">

            <Grid item md={4} sm={12} xs={12}>
              <Link href="/"><img src={images.pakRailLive} alt="pakrail" /></Link>
              <Typography component="h2">Train Schedule</Typography>
            </Grid>

            <Grid container item md={6} sm={9} xs={12} alignItems="center">

              <Autocomplete
                options={allTrains}
                getOptionLabel={(option) => option.TrainName}
                onChange={(event, value) => getTrainStationsByTrainID(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Search Train" variant="outlined" />
                )}
              />

            </Grid>

            <Grid container item md={2} sm={3} xs={12} alignItems="center">

              <Link href={"/train-schedule-map/" + trainId}>
                <Button variant="contained">
                  Map View
                </Button>
              </Link>

            </Grid>

          </Grid>
        </Container>
      </div>

      {/* ========== Selected Train ========== */}

      <div className="selected-train">
        <Typography component="h1">{trainName}</Typography>
      </div>

      {/* ========== Train Grid ========== */}

      <div className="train-grid">
        <Container maxWidth="lg">
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={10} xs={12}>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '40px', }}><b>Sr. #</b></TableCell>
                      <TableCell><b>Station Name</b></TableCell>
                      <TableCell><b>Arrival</b></TableCell>
                      <TableCell><b>Departure</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? markerPositions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : markerPositions
                    ).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {(serialNumber) + (index + 1)}
                        </TableCell>
                        <TableCell>{row.StationName}</TableCell>
                        <TableCell>{row.ArrivalTime === null ? '--:--' : row.ArrivalTime}</TableCell>
                        <TableCell>{row.DepartureTime === null ? '--:--' : row.DepartureTime}</TableCell>
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        colSpan={3}
                        count={markerPositions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changeTablePage}
                        onRowsPerPageChange={changeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>
        </Container>

      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="">
        <div className="" key={currentPath}>
          <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-2174876305920391'
            data-ad-slot='4162879475'
            data-ad-format='auto'
            data-full-width-responsive="true">
          </ins>
        </div>
      </div> */}

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default TrainScheduleList;