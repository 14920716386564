import React from 'react'
import images from '../assets/Image';

import { Container, Grid, Typography, Link, Divider, useMediaQuery } from '@material-ui/core';
import { Responsive } from '../utils/index';


function Footer() {

  const isMobile = useMediaQuery(Responsive.isMobile)
  const isTablet = useMediaQuery(Responsive.isTablet);

  return (
    <div id="Footer">
      <Container maxWidth="lg">

        <Grid container spacing={isMobile ? 2 : isTablet ? 4 : 6}>

          <Grid item sm={6} xs={12}>
            <img src={images.logo} alt="PakRailLive" />
            <p>Pakistan Railways provide an important mode of Transportation in the farthest corners of the country and brings them closer for Business, sight seeing, pilgrimage and education. It has been a great integrating force and forms the life line of the country by catering to its needs for large scale movement of people and freight.</p>
          </Grid>

          <Grid item sm={3} xs={12}>
            <Typography component="h3">Services</Typography>
            <Divider />
            <Typography component="ul">
              <Typography component="li" ><Link href="/live-tracking">Live Tracking</Link></Typography>
              <Typography component="li"><Link href="/">Train Update</Link></Typography>
              <Typography component="li"><Link href="/train-schedule">Train Schedule</Link></Typography>
              <Typography component="li"><Link href="/station-update">Station Update</Link></Typography>
              <Typography component="li"><Link href="/station-schedule">Station Schedule</Link></Typography>
            </Typography>
          </Grid>

          <Grid item sm={3} xs={12}>
            <Typography component="h3">Quick Links</Typography>
            <Divider />
            <Typography component="ul">
              <Typography component="li" ><Link href="/">Home</Link></Typography>
              <Typography component="li" ><Link href="/about-us">About Us</Link></Typography>
              <Typography component="li" ><Link href="/contact-us">Contact Us</Link></Typography>
              <Typography component="li" ><Link href="/terms-conditions">Terms &amp; Conditions</Link></Typography>
            </Typography>
          </Grid>

        </Grid>

        {/* <Grid container spacing={2} className="copyright">
          <Grid item md={12}>
            <p>© Copyright 2021 Directorate of Information Technology Pakistan Railways. All Rights Reserved.</p>
          </Grid>
        </Grid> */}

      </Container>
    </div>
  );
}
export default Footer;