
import io from 'socket.io-client';

export const socketConfig = () => {

    //const socket = io("http://198.71.60.173:8098",
    const socket = io("https://socket.pakraillive.com/",
        {
            rejectUnauthorized: false,
            reconnection: true,
            debug: true,
        });

    return socket;
}