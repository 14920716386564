
import json2mq from "json2mq";

export const Responsive = {
    isMobile: json2mq({ maxWidth: 424, }),
    isTablet: json2mq({ maxWidth: 768, minWidth: 425 }),
};

//* get date and  month from ride id
export const getDateMonth = (rideID) => {
    try {
        let ddMM = rideID.slice(-4);
        let date = ddMM.substring(0, ddMM.length - 2);
        let month = ddMM.slice(-2);
        month = month.substring(0, month.length - 1) === '0' ? month.slice(-1) : month;

        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let selectedMonthName = months[month - 1];

        let dateMonth = date + ' ' + selectedMonthName;

        return dateMonth;
    } catch (e) {
        console.log(e)
    }
}

//* get last update from socket
export const getLastUpdate = (dateTime) => {
    try {
        let utcTime = new Date(+dateTime * 1000);
        let utcHour = utcTime.getHours();
        let utcMinute = utcTime.getMinutes();

        let currTime = new Date();
        let currHour = currTime.getHours();
        let currMinute = currTime.getMinutes();

        let hours = Math.abs(currHour - utcHour);
        let minutes = Math.abs(currMinute - utcMinute);
        let lastUpdate;

        if (hours === 0 && minutes > 0) {
            lastUpdate = minutes + " min ago";
        } else if (minutes === 0) {
            lastUpdate = 'just now';
        } else {
            lastUpdate = hours + " hr " + minutes + " min ago";
        }
        return lastUpdate;
    } catch (e) {
        console.log(e)
    }
}

// *get last update for single train in time format HH:MM
export const getTrainLastUpdate = (dateTime) => {
    try {
        let utcTime = new Date(+dateTime * 1000);
        let utcHour = utcTime.getHours();
        let utcMinute = utcTime.getMinutes();

        let lastUpdate; //= utcHour+":"+utcMinute;
        let format;

        let num = (+utcHour % 12 || 12) * 60 + (+utcMinute);
        let hours = Math.floor(num / 60);
        let minutes = num % 60;
        minutes = minutes <= 9 ? '0' + minutes : minutes;

        if (hours > 12) {
            format = ' pm';
        } else {
            if (+utcHour > 12) {
                format = ' pm';
            } else {
                format = ' am';
            }
        }
        hours = +hours % 12 || 12; // Adjust hours
        lastUpdate = hours + ":" + minutes + format;

        return lastUpdate;
    } catch (e) {
        console.log(e)
    }
}

//* convert json number into time format
export const timeConvert = (num) => {
    try {
        let early = '';

        if (num < 0) {
            early = 'early';
        }

        let hours = Math.floor(Math.abs(num) / 60);
        let minutes = Math.abs(num) % 60;
        let time;

        if (hours === 0) {
            time = minutes + " min " + early;
        } else if (minutes === 0) {
            time = 'none';
        } else {
            time = hours + " hr " + minutes + " min " + early;
        }

        return time;
    } catch (e) {
        console.log(e)
    }
}

//* get all stations of selected train
export const getTrainStation = (stationArray, stationID) => {
    let result;
    let i;
    try {
        for (i = 0; i <= stationArray.length; i++) {
            if (stationArray[i]['StationDetailsId'] === stationID) {
                result = stationArray[i]['StationName'];
                break;
            }
        }
        return result;
    } catch (e) {
        result.error = e;
    } finally {
        return result;
    }
}

//* calculate train estimated arrival time
export const getEstArrivalTime = (stationArray, stationID, delayTime) => {
    let result;
    let estdTimeArrival;
    let format = '';
    let i;
    try {
        for (i = 0; i <= stationArray.length; i++) {
            if (stationArray[i]['StationDetailsId'] === stationID) {
                result = stationArray[i]['ArrivalTime'];
                break;
            }
        }

        let split = result.split(':');
        let arrivalTime = (+split[0] % 12 || 12) * 60 + (+split[1]);

        let num = parseInt(arrivalTime) + parseInt(delayTime);
        let hours = Math.floor(num / 60);
        let minutes = num % 60;
        minutes = minutes <= 9 ? '0' + minutes : minutes;

        if (hours > 12) {
            format = ' pm';
        } else {
            if (+split[0] > 12) {
                format = ' pm';
            } else {
                format = ' am';
            }
        }
        hours = +hours % 12 || 12; // Adjust hours
        estdTimeArrival = hours + ":" + minutes + format;

        return estdTimeArrival;
    } catch (e) {
        estdTimeArrival.error = e;
    } finally {
        return estdTimeArrival;
    }
}

//* calculate train estimated arrival time for info window
export const getInfoWindowEstArrivalTime = (arrivalTime, delayTime) => {
    let estdTimeArrival;
    let format = '';
    try {
        let split = arrivalTime.split(':');
        let splitTime = (+split[0] % 12 || 12) * 60 + (+split[1]);

        let num = parseInt(splitTime) + parseInt(delayTime);
        let hours = Math.floor(num / 60);
        let minutes = num % 60;

        if (hours > 12) {
            format = ' pm';
        } else {
            if (+split[0] > 12) {
                format = ' pm';
            } else {
                format = ' am';
            }
        }

        minutes = minutes <= 9 ? '0' + minutes : minutes;

        hours = +hours % 12 || 12; // Adjust hours
        estdTimeArrival = hours + ":" + minutes + format;

        return estdTimeArrival;
    } catch (e) {
        estdTimeArrival.error = e;
    } finally {
        return estdTimeArrival;
    }
}

//* get selected train kmlUrl
export const getTrainKmlUrl = async trainName => {
    console.log('file: index.js => line 216 => trainName', trainName);
    let url = '';
    try {
        const baseUrl = 'https://www.pakraillive.com/kml/';
        const ext = '.kml';
        const name = trainName.replace(/ /g, "-");

        url = baseUrl + name + ext;

        return url;
    } catch (e) {
        url.error = e;
    } finally {
        return url;
    }
}

//* convert time format
export const convertTimeFormat = (time) => {
    let departureTime = '';
    let format = '';
    try {
        let split = time.split(':');

        if (+split[0] <= 12) {
            format = ' am';
            departureTime = time + format;
        } else {
            split[0] = +split[0] % 12 || 12; // Adjust hours
            format = ' pm';
            departureTime = split[0] + ':' + split[1] + format;
        }

        return departureTime;
    } catch (e) {
        departureTime.error = e;
    } finally {
        return departureTime;
    }
}

//* disable developer inspect tool options
export const disabledInspect = (time) => {
    document.onkeydown = function (e) {
        if (e.keyCode === 123) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode === 'M'.charCodeAt(0)) {
            return false;
        }
        if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
            return false;
        }
    }
}

export const getDate = (date) => {
    var today;

    if (typeof date.getMonth === 'function') today = date;
    else today = new Date(date);

    if (today.getDate) {
        var dd = String(today?.getDate()).padStart(2, '0');
        var mm = String(today?.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today?.getFullYear();
        // const SalesDate = mm + '/' + dd + '/' + yyyy
        today = mm + '-' + dd + '-' + yyyy;
    }
    return today;
};

export const isUpperCase = value => {
    return !/[a-z]/.test(value) && /[A-Z]/.test(value);
};

export const getValue = (value) => {
    const date = new Date(value);

    if (typeof value === 'number') {
        return value;
    }

    let result = '';
    if (typeof value === 'string' && isNaN(date.getDate())) {
        if (value.includes('_')) {
            let splitValue = value.split('_');
            for (const part in splitValue) {
                let element = splitValue[part];
                element = element.charAt(0).toUpperCase() + element.slice(1);
                result = result + ' ' + element;
            }
            return result.trim();
        }
        else if (value.includes('-')) {
            let splitValue = value.split('-');
            for (const part in splitValue) {
                let element = splitValue[part];
                element = element.charAt(0).toUpperCase() + element.slice(1);
                result = result + ' ' + element;
            }
            return result.trim();
        }
        else {
            result = isUpperCase(value) ? value : value.replace(/([A-Z])/g, ' $1');
            result = result.charAt(0).toUpperCase() + result.slice(1);
            return result.trim();
        }
    } else if (!isNaN(date.getDate())) {
        return getDate(date);
    } else return value;
};

