const geojsons = {
    'All-Trains': require('./geojson/All-Trains.json'),
    'Allama-Iqbal-Express-9UP': require('./geojson/Allama-Iqbal-Express-9UP.json'),
    'Allama-Iqbal-Express-10DN': require('./geojson/Allama-Iqbal-Express-10DN.json'),
    'Awam-Express-13UP': require('./geojson/Awam-Express-13UP.json'),
    'Awam-Express-14DN': require('./geojson/Awam-Express-14DN.json'),
    'Badar-Express-111UP': require('./geojson/Badar-Express-111UP.json'),
    'Badar-Express-112DN': require('./geojson/Badar-Express-112DN.json'),
    'Bahauddin-Zikria-Express-25UP': require('./geojson/Bahauddin-Zikria-Express-25UP.json'),
    'Bahauddin-Zikria-Express-26DN': require('./geojson/Bahauddin-Zikria-Express-26DN.json'),
    'Cargo-Train-Premium-Container-303UP': require('./geojson/Cargo-Train-Premium-Container-303UP.json'),
    'faisalabad express 119': require('./geojson/faisalabad express 119.json'),
    'faisalabad express 173': require('./geojson/faisalabad express 173.json'),
    'Fareed-Express-37UP': require('./geojson/Fareed-Express-37UP.json'),
    'Fareed-Express-38DN': require('./geojson/Fareed-Express-38DN.json'),
    'Ghouri-Express-113UP': require('./geojson/Ghouri-Express-113UP.json'),
    'Ghouri-Express-114DN': require('./geojson/Ghouri-Express-114DN.json'),
    'Green-Line-5UP': require('./geojson/Green-Line-5UP.json'),
    'Green-Line-6DN': require('./geojson/Green-Line-6DN.json'),
    'Hazara-Express-11UP': require('./geojson/Hazara-Express-11UP.json'),
    'Hazara-Express-12DN': require('./geojson/Hazara-Express-12DN.json'),
    'Islamabad-Express-107UP': require('./geojson/Islamabad-Express-107UP.json'),
    'Islamabad-Express-108DN': require('./geojson/Islamabad-Express-108DN.json'),
    'Jaffar-Express-40DN': require('./geojson/Jaffar-Express-40DN.json'),
    'Jaffar-Express-39UP': require('./geojson/Jaffar-Express-39UP.json'),
    'Jinnah-Express-31UP': require('./geojson/Jinnah-Express-31UP.json'),
    'Jinnah-Express-32DN': require('./geojson/Jinnah-Express-32DN.json'),
    'Karachi-Express-15UP': require('./geojson/Karachi-Express-15UP.json'),
    'Karachi-Express-16DN': require('./geojson/Karachi-Express-16DN.json'),
    'Karakoram-Express-41UP': require('./geojson/Karakoram-Express-41UP.json'),
    'Karakoram-Express-42DN': require('./geojson/Karakoram-Express-42DN.json'),
    'Khyber-Mail-1UP': require('./geojson/Khyber-Mail-1UP.json'),
    'Khyber-Mail-2DN': require('./geojson/Khyber-Mail-2DN.json'),
    'MEHR-EXPRESS-127UP': require('./geojson/MEHR-EXPRESS-127UP.json'),
    'MEHR-EXPRESS-128DN': require('./geojson/MEHR-EXPRESS-128DN.json'),
    'MIANWALI-EXPRESS-147UP': require('./geojson/MIANWALI-EXPRESS-147UP.json'),
    'MIANWALI-EXPRESS-148DN': require('./geojson/MIANWALI-EXPRESS-148DN.json'),
    'Millat-Express-17UP': require('./geojson/Millat-Express-17UP.json'),
    'Millat-Express-18DN': require('./geojson/Millat-Express-18DN.json'),
    'Multan express': require('./geojson/Multan express.json'),
    'Musa-Pak-Express-115UP': require('./geojson/Musa-Pak-Express-115UP.json'),
    'Musa-Pak-Express-116DN': require('./geojson/Musa-Pak-Express-116DN.json'),
    'Narowal-Passenger-211UP': require('./geojson/Narowal-Passenger-211UP.json'),
    'Narowal-Passenger-212DN': require('./geojson/Narowal-Passenger-212DN.json'),
    'Pak-Business-33UP': require('./geojson/Pak-Business-33UP.json'),
    'Pak-Business-34DN': require('./geojson/Pak-Business-34DN.json'),
    'Pakistan-Express-45UP': require('./geojson/Pakistan-Express-45UP.json'),
    'Pakistan-Express-46DN': require('./geojson/Pakistan-Express-46DN.json'),
    'Rawal-Express-105UP': require('./geojson/Rawal-Express-105UP.json'),
    'Rawal-Express-106DN': require('./geojson/Rawal-Express-106DN.json'),
    'Rawalpindi express': require('./geojson/Rawalpindi express.json'),
    'Rehman-Baba-Express-47UP': require('./geojson/Rehman-Baba-Express-47UP.json'),
    'Rehman-Baba-Express-48DN': require('./geojson/Rehman-Baba-Express-48DN.json'),
    'sindh express': require('./geojson/sindh express.json'),
    'Sabak-Raftar-101UP': require('./geojson/Sabak-Raftar-101UP.json'),
    'Sabak-Raftar-104DN': require('./geojson/Sabak-Raftar-104DN.json'),
    'Shah-Hussain-Express-43UP': require('./geojson/Shah-Hussain-Express-43UP.json'),
    'Shah-Hussain-Express-44DN': require('./geojson/Shah-Hussain-Express-44DN.json'),
    'Shalimar-Express-27UP': require('./geojson/Shalimar-Express-27UP.json'),
    'Shalimar-Express-28DN': require('./geojson/Shalimar-Express-28DN.json'),
    'Sir-Sayyed-Express-35UP': require('./geojson/Sir-Sayyed-Express-35UP.json'),
    'Sir-Sayyed-Express-36DN': require('./geojson/Sir-Sayyed-Express-36DN.json'),
    'Subak-Kharam-102DN': require('./geojson/Subak-Kharam-102DN.json'),
    'Subak-Kharam-103UP': require('./geojson/Subak-Kharam-103UP.json'),
    'Sukkur-Express-145UP': require('./geojson/Sukkur-Express-145UP.json'),
    'Sukkur-Express-146DN': require('./geojson/Sukkur-Express-146DN.json'),
    'Tezgam-7UP': require('./geojson/Tezgam-7UP.json'),
    'Tezgam-8DN': require('./geojson/Tezgam-8DN.json'),
};

export default geojsons;