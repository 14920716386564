import React, { useEffect } from "react";

import Header from '../layouts/header.js';
import Footer from '../layouts/footer.js';

import { Container, Grid, Divider, Typography, useMediaQuery } from '@material-ui/core';
import { Responsive, disabledInspect } from '../utils/index';


function AboutUs(props) {

  const isMobile = useMediaQuery(Responsive.isMobile);
  const isTablet = useMediaQuery(Responsive.isTablet);

  const { currentPath } = props;

  useEffect(() => {
    disabledInspect();
    // window.adsbygoogle = window.adsbygoogle || []
    // window.adsbygoogle.push({})
    window.scrollTo({ top: 0 });
  }, [currentPath]);

  return (
    <div id="About-Us">

      {/* ========== Header ========== */}

      <Header />

      {/* ========== Banner ========== */}

      <div className="banner">
        <div className="overlay"></div>
        <h1>About Us</h1>
      </div>

      {/* ========== Google Ads ========== */}

      {/* <div className="">
        <Container maxWidth="lg">
          <div className="" key={currentPath}>
            <ins className='adsbygoogle'
              style={{ display: 'block' }}
              data-ad-client='ca-pub-2174876305920391'
              data-ad-slot='4162879473'
              data-ad-format='auto'
              data-full-width-responsive="true">
            </ins>
          </div>
        </Container>
      </div> */}

      {/* ========== About Us ========== */}

      <div className="about">
        <Container maxWidth="lg">

          <Grid container spacing={isMobile ? 2 : isTablet ? 4 : 6} justifyContent="space-evenly">

            <Grid item md={12}>

              <Typography component="h2">About Us</Typography>
              <Divider />
              <Typography component="p">Pakistan Railways provide an important mode of Transportation in the farthest corners of the country and brings them closer for Business, sight seeing, pilgrimage and education. It has been a great integrating force and forms the life line of the country by catering to its needs for large scale movement of people and freight.</Typography>
              <Typography component="p">The possibility of Karachi as a sea port was first noticed in the mid of 19th century and Sir Henry Edward Frere who was appointed Commissioner of Sindh after its annexation with Bombay in 1847 sought permission from Lord Dalhousie to begin survey of sea port. He also initiated the survey for Railway line in 1858 . It was proposed that a railway line from Karachi City to Kotri, steam navigation up the Indus /Chenab upto Multan and from there another railway line to Lahore and beyond be constructed.</Typography>
              <Typography component="p">It was on 13th May,1861 that first railway line was opened for public traffic between Karachi City and Kotri, the distance of 105 miles. The line between Karachi City and Kimari was opened on 16.6.1889. By 1897 the line from Kimari to Kotri was doubled.</Typography>
              <Typography component="p">The railway line from Peshawar to Karachi closely follows Alexander’s line of march through the Hindu Kush to the sea. Different sections on existing main line from Peshawar to Lahore and Multan and branch lines were constructed in the last quarter of 19th century and early years of 20th century.</Typography>
              <Typography component="p">The 4 sections i.e. Scinde Railways, Indian Flotilla Company, Punjab Railway and Delhi Railways working in a single company were later on amalgamated into Scinde, Punjab & Delhi Railways Company and was purchased by the Secretary of State for India in 1885 and in January, 1886 it was named North Western State Railways which was later on renamed as North Western Railways.</Typography>
              <Typography component="p">At the time of partition, North Western Railways 1847 route mile was transferred to India leaving route miles 5048 to Pakistan. In 1954 The railway line was extended to Mardan and Charsada section and in 1956 Jacababad-Kashmore 2’-6’’ line was converted into broad gauge. Kot Adu-Kashmore line was constructed between 1969 to 1973 providing an alternate route from Karachi to up country.</Typography>

            </Grid>

          </Grid>

        </Container>
      </div>

      {/* ========== Footer ========== */}

      <Footer />

    </div>
  );
}

export default AboutUs;