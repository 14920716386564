
const images = {
  logo: require('./images/pakrail-logo.png').default,
  pakRailLive: require('./images/pakraillive.png').default,
  banner: require('./images/pakrail-bg-attachment.jpg').default,
  downloadBg: require('./images/application-bg-attachment.jpg').default,
  appMobile: require('./images/application-hand.png').default,
  googleAds: require('./images/google-ads.png').default,
  googleAdsVertical: require('./images/google-ads-vertical.jpeg').default,
  nextStation: require('./images/next-station.png').default,
  prevStation: require('./images/prev-station.png').default,
  lastStation: require('./images/last-station.png').default,
  // upTrain: require('./images/up-train.gif').default,
  // dnTrain: require('./images/down-train.gif').default,
  upTrain: require('./images/up-train.png').default,
  dnTrain: require('./images/down-train.png').default,
  depDate: require('./images/departure-date.png').default,
  estArrival: require('./images/estimated-arrival.png').default,
  trainDelay: require('./images/train-delay.png').default,
  lastUpdate: require('./images/last-update.png').default,
  mapMaker: require('./images/map-maker.gif').default,
};

export default images;